.cardsTest {
  min-width: 30vw;
  transition: 0.3s;
  cursor: pointer;
  justify-self: center;
  text-align: center;
  justify-self: center;
}
.cardsTest img {
  border-radius: 50%;
  width: 150px;
  margin: auto;

}
.d-gridTest {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, auto));
  grid-gap: 25px;
  margin-left: 5%;
  margin-right: 5%;
}
@media (min-width: 1400px) {
  .d-gridTest {
    display: grid;
    grid-template-columns: repeat(3, minmax(300px, auto));
    grid-gap: 25px;
    width: 1500px;
    margin: auto;
  }
}
.testify {
  background-color: whitesmoke;
}

.versiculo {
  width: 80%;
  margin: auto;
  justify-content: center;
}